const PageNotFound = () => (
  <section
    className="hero is-light is-large"
    style={{ minHeight: "85vh" /* this matches main-content container */ }}
  >
    <div className="hero-body">
      <div className="container has-text-centered">
        <h1 className="title">404</h1>
        <h2 className="subtitle">Page Not Found</h2>
      </div>
    </div>
  </section>
);

export default PageNotFound;
